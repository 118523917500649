import React from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import { descriptionTextStyle, mainTextStyle, titleTextStyle } from '../../../style/style';
import { PcFifthTopImg } from '../../../static/images';

const boxStyle = css`
  display: grid;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
  background-color: #f2f3f5;

  & > div:not(:last-child) {
    display: grid;
    text-align: center;
    width: 100%;
  }
`;

const imgDivStyle = css`
  display: grid;
  justify-content: center;
  margin-top: 44px;
  overflow: hidden;
`;

const PcVerSectionFifth = () => (
  <div className={boxStyle}>
    <div className={titleTextStyle}>자유로운 커뮤니티</div>
    <div className={mainTextStyle({ fontSize: '32px', marginBottom: '10px', lineHeight: '46px' })}>
      익명 버디들이 친구들과 공유하는 <br /> 솔직한 투자 이야기
    </div>
    <div className={descriptionTextStyle()}>
      커뮤니티에서 만난 새로운 버디들이 <br /> 실제로 거래한 주식/코인에 대해 자유롭게 얘기해봐요.
    </div>
    <div className={imgDivStyle}>
      <img src={PcFifthTopImg} alt={'buddystock_fifth_top'} />
    </div>
  </div>
);

export default PcVerSectionFifth;
