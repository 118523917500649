import React, { ReactNode } from 'react';
import '../index.css';
import { css } from '@emotion/css';

const buttonStyle = ({
  color,
  width,
  size,
  isMobile
}: {
  color: 'primary' | 'white' | 'gray';
  width: number;
  size: 'default' | 'small';
  isMobile: boolean;
}) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${width}px;
  height: ${size === 'small' ? 50 : 56}px;
  background: ${(color === 'primary' && '#091E42') ||
  (color === 'gray' && '#ebebeb') ||
  (color === 'white' && '#ffffff')};
  color: ${(color === 'primary' && '#ffffff') || (color === 'gray' && '#242424') || (color === 'white' && '')};
  border-radius: 8px;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-right: ${isMobile ? '' : '16px'};
    margin-bottom: ${isMobile ? '16px' : ''};
  }

  &:hover {
    background: ${(color === 'primary' && '#253858') ||
    (color === 'gray' && '#ebebeb') ||
    (color === 'white' && '#f2f3f5')};
  }
`;

const buttonTextStyle = css`
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.2px;
  margin-left: 4px;
`;

interface IIconButton {
  label: string;
  icon: ReactNode;
  color: 'primary' | 'white' | 'gray';
  width: number;
  onClick: () => void;
  size?: 'default' | 'small';
  isMobile?: boolean;
}

const IconButton = ({ label, icon, color, width, onClick, size = 'default', isMobile = false }: IIconButton) => (
  <div className={buttonStyle({ color, width, size, isMobile })} onClick={onClick}>
    {icon}
    <div className={buttonTextStyle}>{label}</div>
  </div>
);

export default IconButton;
