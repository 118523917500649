import React from 'react';
import '../../../index.css';
import MobileDefaultTemplate from '../MobileDefaultTemplate';
import { bottomImageStyle, topImageStyle } from '../../../style/style';
import { MobileFirstTopImg } from '../../../static/images';

const MobileVerSectionFirst = () => (
  <MobileDefaultTemplate
    isFirst
    backgroundColor={'#fff'}
    text={'솔직한 투자 이야기는 버디스탁에서'}
    description={
      <span>
        주식과 코인으로 재테크 하는 세상, <br /> 가까운 내 친구들은 무엇을 거래하고 있을까요?
      </span>
    }
    textWidth={234}
    desWidth={268}
    imgContainerStyle={{ margin: '26px auto 80px' }}
  >
    <div className={topImageStyle({})}>
      <img src={MobileFirstTopImg} alt={'buddystock_top_img'} />
    </div>
  </MobileDefaultTemplate>
);

export default MobileVerSectionFirst;
