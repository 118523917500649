import React from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import { BuddystockAppleStoreIcon, BuddystockGooglePlayIcon, BuddystockLogoIcon } from '../../../static/images';
import { mainTextStyle } from '../../../style/style';
import IconButton from '../../IconButton';
import { APP_STORE } from '../../../constants';

const rootStyle = css`
  width: 100%;
  min-width: 320px;
  display: grid;
  justify-content: center;
  padding: 104px 0px 40px;
  background-color: #091e42;
`;

const centerContainerStyle = css`
  display: grid;
  justify-content: center;
`;

const imgStyle = css`
  background-color: #fff;
  width: 120px;
  height: 120px;
  border-radius: 30px;
  display: flex;
  align-items: center;
`;

const customTextStyle = {
  display: 'grid',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '24px',
  color: '#ffffff',
  marginTop: '32px',
  width: '226px',
  lineHeight: '34px'
};

const buttonStyle = css`
  display: flex;
  gap: 12px;
  margin-top: 170px;
`;

const MobileVerSectionSixth = () => (
  <div className={rootStyle}>
    <div className={centerContainerStyle}>
      <div className={imgStyle}>
        <img src={BuddystockLogoIcon} alt={'buddystock_sixth_top'} width={120} height={120} />
      </div>
    </div>
    <div className={centerContainerStyle}>
      <div className={mainTextStyle(customTextStyle)}>
        <span>
          지금 바로 버디스탁을
          <br /> 만나보세요
        </span>
      </div>
    </div>
    <div className={buttonStyle}>
      <IconButton
        isMobile
        size={'small'}
        label={'Google Play'}
        icon={<BuddystockGooglePlayIcon />}
        color={'white'}
        width={162}
        onClick={() => window.open(APP_STORE.GOOGLE_PLAY, ' _blank')}
      />
      <IconButton
        isMobile
        size={'small'}
        label={'Apple Store'}
        icon={<BuddystockAppleStoreIcon />}
        color={'white'}
        width={162}
        onClick={() => window.open(APP_STORE.APPLE, ' _blank')}
      />
    </div>
  </div>
);

export default MobileVerSectionSixth;
