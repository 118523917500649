import React, { useState } from 'react';
import { css } from '@emotion/css';
import { Mobile, PC } from '../../components/MediaQuery';
import PcVerNav from '../../components/PageNav/pcVerNav';
import PcVerFooter from '../../components/PageFooter/pcVerFooter';
import MobileVerHeaderNav from '../../components/PageNav/mobileVerHeaderNav';
import MobileVerFooter from '../../components/PageFooter/mobileVerFooter';
import {
  MobileVerSectionFifth,
  MobileVerSectionFirst,
  MobileVerSectionFourth,
  MobileVerSectionSecond,
  MobileVerSectionSeventh,
  MobileVerSectionSixth,
  MobileVerSectionThird,
  PcVerSectionFifth,
  PcVerSectionFirst,
  PcVerSectionFourth,
  PcVerSectionSecond,
  PcVerSectionSeventh,
  PcVerSectionSixth,
  PcVerSectionThird
} from '../../components/Section';
import MobileVerFooterNav from '../../components/PageNav/mobileVerFooterNav';
import MobileVerHeaderNavMenu from '../../components/PageNav/mobileVerHeaderNavMenu';

const overLayStyle = css`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  min-width: 320px;
  height: 100%;
  z-index: 35;
  background: #000000;
  opacity: 0.5;
`;

const Main = () => {
  const [openMenuPopup, setOpenMenuPopup] = useState(false);

  return (
    <>
      <PC>
        <PcVerNav />
        <PcVerSectionFirst />
        <PcVerSectionSecond />
        <PcVerSectionThird />
        <PcVerSectionFourth />
        <PcVerSectionFifth />
        <PcVerSectionSixth />
        <PcVerSectionSeventh />
        <PcVerFooter />
      </PC>
      <Mobile>
        <div>
          <MobileVerHeaderNav openMenuPopup={openMenuPopup} setOpenMenuPopup={setOpenMenuPopup} />
          {!openMenuPopup && (
            <>
              <MobileVerSectionFirst />
              <MobileVerSectionSecond />
              <MobileVerSectionThird />
              <MobileVerSectionFourth />
              <MobileVerSectionFifth />
              <MobileVerSectionSixth />
              <MobileVerSectionSeventh />
              <MobileVerFooter />
              <MobileVerFooterNav />
            </>
          )}

          {openMenuPopup && <MobileVerHeaderNavMenu />}
        </div>
      </Mobile>
    </>
  );
};
export default Main;
