import React, { ReactNode } from 'react';
import { css } from '@emotion/css';
import { mainTextStyle } from '../../style/style';

export const rootStyle = (bgColor: string) => css`
  background-color: ${bgColor};
  width: 100%;
  min-width: 320px;
  display: grid;
  justify-content: center;
  padding-top: 40px;
`;

const centerContainer = css`
  display: flex;
  justify-content: center;
`;

const titleStyle = css`
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 16px;
  color: #00aeb0;
`;

const textStyle = (textWidth: number, isFirst: boolean, isTextColorChange: boolean) => css`
  text-align: center;
  font-size: ${isFirst ? 28 : 24}px;
  width: ${textWidth || 234}px;
  line-height: 34px;
  color: ${isTextColorChange ? '#fff' : '#000'};
`;

const descriptionStyle = (desWidth: number, isTextColorChange: boolean) => css`
  width: ${desWidth || 262}px;
  text-align: center;
  margin-top: 16px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${isTextColorChange ? '#B3BAC5' : '#7a869a'};
  word-break: keep-all;
`;

const imageContainerStyle = (other?: any) => css`
  position: relative;
  height: 320px;
  width: 238px;
  display: flex;
  justify-content: center;
  ${other};
`;

const MobileDefaultTemplate = ({
  children,
  backgroundColor,
  text,
  textWidth = 0,
  title,
  description,
  desWidth = 0,
  customRootStyle,
  imgContainerStyle,
  isFirst = false,
  isTextColorChange = false
}: {
  children: ReactNode;
  text: string;
  backgroundColor?: string;
  title?: string;
  description?: string | ReactNode;
  desWidth?: number;
  textWidth?: number;
  customRootStyle?: any;
  imgContainerStyle?: any;
  isFirst?: boolean;
  isTextColorChange?: boolean;
}) => (
  <div className={customRootStyle || rootStyle(backgroundColor || '')}>
    {title && (
      <div className={centerContainer}>
        <div className={titleStyle}>{title}</div>
      </div>
    )}
    <div className={centerContainer}>
      <span className={mainTextStyle(textStyle(textWidth, isFirst, isTextColorChange))}>{text}</span>
    </div>

    {description && (
      <div className={centerContainer}>
        <div className={descriptionStyle(desWidth, isTextColorChange)}>{description}</div>
      </div>
    )}
    <div className={imageContainerStyle(imgContainerStyle)}>{children}</div>
  </div>
);

export default MobileDefaultTemplate;
