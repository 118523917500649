import React, { ReactNode } from 'react';
import '../../index.css';
import { css } from '@emotion/css';
import { FOOTHER_ITEMS } from './constants';
import { BuddystockTitleIcon } from '../../static/images';

const rootStyle = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 42px 0px;
  background-color: #dfe1e6;
`;

const containerStyle = css`
  display: flex;
  width: 92%;
  max-width: 1008px;
  margin: 0 auto;
  justify-content: space-between;
`;

const footerItemsDivStyle = css`
  display: flex;
`;

const footerItemStyle = css`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.2px;
  &:not(:last-of-type) {
    margin-right: 32px;
  }
  color: #42526e;
`;

const copyrightTextStyle = css`
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.2px;
  color: #666666;
`;

interface IItem {
  label: ReactNode;
  event?: () => void;
}

const FooterItemComponent = ({ items }: { items: IItem[] }) => {
  return (
    <div className={footerItemsDivStyle}>
      {items.map((item: IItem, index: number) => {
        return (
          <div key={index} className={footerItemStyle} onClick={item.event}>
            {item.label}
          </div>
        );
      })}
    </div>
  );
};

const PcVerFooter = () => {
  return (
    <div className={rootStyle}>
      <div className={containerStyle}>
        <img src={BuddystockTitleIcon} width={114} height={30} alt={'buddystock_title'} />
      </div>
      <div className={containerStyle}>
        <FooterItemComponent items={FOOTHER_ITEMS} />
        <div className={copyrightTextStyle}>© BUDDYSTOCK. ALL RIGHTS RESERVED.</div>
      </div>
    </div>
  );
};

export default PcVerFooter;
