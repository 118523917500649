import React from 'react';
import '../../../index.css';
import { topImageStyle } from '../../../style/style';
import { MobileFourthTopImg } from '../../../static/images';
import MobileDefaultTemplate from '../MobileDefaultTemplate';

const MobileVerSectionFourth = () => (
  <MobileDefaultTemplate
    title={'유용한 정보 관리'}
    backgroundColor={'#fff'}
    text={'내 주변 숨은 고수들과 함께 소통하는'}
    description={
      <span>
        친구의 친구가 얼마나 투자를 잘하는지, <br /> 어떤 종목을 가지고 있는지 궁금하다면, 버디스탁에서 확인해보세요!
      </span>
    }
    desWidth={240}
    imgContainerStyle={{ margin: '26px auto -3px' }}
  >
    <div className={topImageStyle({})}>
      <img src={MobileFourthTopImg} alt={'buddystock_top_img'} />
    </div>
  </MobileDefaultTemplate>
);

export default MobileVerSectionFourth;
