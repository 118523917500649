import React from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import MobileDefaultTemplate from '../MobileDefaultTemplate';
import { topImageStyle } from '../../../style/style';
import { MobileThirdTopImg } from '../../../static/images';

const containerStyle = css`
  display: flex;
  width: 308px;
  padding: 44px 0 119px;
  justify-content: space-between;
`;

const imageContainerStyle = css`
  position: relative;
  width: 132px;
  height: 320px;
  word-break: keep-all;
`;

const MobileVerSectionThird = () => (
  <MobileDefaultTemplate
    backgroundColor={'#fff'}
    title={'안전한 거래 정보'}
    text={'오직 내 친구에게만 알려주는 매매 종목'}
    textWidth={236}
    description={'걱정 마세요. 거래량은 친구에게도 공개되지 않아요! 오직 거래 종목만 공개됩니다.'}
    desWidth={291}
    imgContainerStyle={{ margin: '0px auto 0px' }}
  >
    <div className={containerStyle}>
      <div className={imageContainerStyle}>
        <div className={topImageStyle({ left: '-38%' })}>
          <img src={MobileThirdTopImg} alt={'buddystock_top_img'} />
        </div>
      </div>
    </div>
  </MobileDefaultTemplate>
);

export default MobileVerSectionThird;
