import React from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import { PcFourthTopImg } from '../../../static/images';
import {
  bottomImageStyle,
  descriptionTextStyle,
  containerStyle,
  imageBoxStyle,
  mainTextStyle,
  topImageStyle,
  rootStyle,
  contentStyle,
  titleTextStyle
} from '../../../style/style';

const PcVerSectionFourth = () => (
  <div className={rootStyle()}>
    <div className={containerStyle}>
      <div className={contentStyle({ paddingBottom: '254px', alignItems: 'center' })}>
        <div className={imageBoxStyle({ width: '414px', height: '520px' })}>
          <div className={topImageStyle({ left: '-10%' })}>
            <img src={PcFourthTopImg} alt={'buddystock_top_img'} />
          </div>
        </div>
        <div className={css({ width: '434px', marginLeft: '52px' })}>
          <div className={titleTextStyle}>유용한 정보 관리</div>
          <div className={mainTextStyle({ fontSize: '36px', marginBottom: '30px', lineHeight: '46px' })}>
            내 주변 숨은 고수들과
            <br /> 함께 소통하는
          </div>
          <div className={descriptionTextStyle()}>
            친구의 친구가 얼마나 투자를 잘하는지,
            <br /> 어떤 종목을 가지고 있는지 궁금하다면,
            <br /> 버디스탁에서 확인해보세요!
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PcVerSectionFourth;
