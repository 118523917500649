import React, { ReactNode } from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import { BuddystockChartIcon, BuddystockUpIcon, PcThirdTopImg } from '../../../static/images';
import {
  containerStyle,
  contentStyle,
  descriptionTextStyle,
  imageBoxStyle,
  mainTextStyle,
  rootStyle,
  titleTextStyle,
  topImageStyle
} from '../../../style/style';

const PcVerSectionThird = () => (
  <div className={rootStyle()}>
    <div className={containerStyle}>
      <div className={contentStyle({ padding: '0px 116px' })}>
        <div className={css({ width: '376px', marginTop: '40px' })}>
          <div className={titleTextStyle}>안전한 거래 정보</div>
          <div className={mainTextStyle({ fontSize: '36px', lineHeight: '46px' })}>
            오직 내 친구에게만 <br />
            알려주는 매매 종목
          </div>
          <div className={descriptionTextStyle()}>
            걱정 마세요. 거래량은 친구에게도 공개되지 않아요! 오직 거래 종목만 공개됩니다.
          </div>
        </div>
        <div className={imageBoxStyle({ width: '368px', height: '396px' })}>
          <div className={topImageStyle({ top: '-5%' })}>
            <img src={PcThirdTopImg} alt={'buddystock_top_img'} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PcVerSectionThird;
