import React, { Dispatch, SetStateAction } from 'react';
import '../../index.css';
import { css } from '@emotion/css';
import { BuddystockLogoIcon, BuddystockTitleIcon } from '../../static/images';
import { CloseIcon, MenuIcon } from '../../static/icons';

const navStyle = css`
  position: sticky;
  top: 0;
  width: 100%;
  min-width: 320px;
  background-color: #ffffff;
  z-index: 30;
  border-bottom: 1px solid #ebebeb;
`;

const containerStyle = css`
  piosition: relative;
  display: flex;
  width: 92%;
  margin: 0 auto;
  padding: 16px 0px;
`;

const logoStyle = css`
  display: flex;
  flex: 1;
  justify-content: start;
  align-items: center;
`;

const menuStyle = css`
  display: flex;
  justify-content: end;
  cursor: pointer;
`;

const MobileVerHeaderNav = ({
  openMenuPopup,
  setOpenMenuPopup
}: {
  openMenuPopup: boolean;
  setOpenMenuPopup: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <div className={navStyle}>
      <div className={containerStyle}>
        <div className={logoStyle}>
          <img src={BuddystockLogoIcon} width={30} height={30} alt={'buddystock_logo'} />
          <img src={BuddystockTitleIcon} width={114} height={30} alt={'buddystock_title'} />
        </div>
        <div className={menuStyle} onClick={() => setOpenMenuPopup(!openMenuPopup)}>
          {openMenuPopup ? <img src={CloseIcon} width={24} height={24} color={'#000'} /> : <img src={MenuIcon} />}
        </div>
      </div>
    </div>
  );
};

export default MobileVerHeaderNav;
