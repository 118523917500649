import React from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import { PcSecondTopImg } from '../../../static/images';
import {
  descriptionTextStyle,
  imageBoxStyle,
  mainTextStyle,
  contentStyle,
  containerStyle,
  rootStyle,
  topImageStyle,
  titleTextStyle
} from '../../../style/style';

const PcVerSectionSecond = () => (
  <div className={rootStyle()}>
    <div className={containerStyle}>
      <div className={contentStyle({ padding: '90px 0px 115px' })}>
        <div className={imageBoxStyle({ height: '520px' })}>
          <div className={topImageStyle({ top: '-7%' })}>
            <img src={PcSecondTopImg} alt={'buddystock_top_img'} />
          </div>
        </div>
        <div className={css({ width: '436px', marginTop: '80px' })}>
          <div className={titleTextStyle}>실시간 매매 내역</div>
          <div className={mainTextStyle({ fontSize: '36px', lineHeight: '46px' })}>
            친구가 사고 파는 주식과 코인을 실시간으로
          </div>
          <div className={descriptionTextStyle('')}>
            연락처와 닉네임으로 친구를 찾고, <br /> 친구들의 주식과 코인 거래 정보를 <br />
            실시간으로 공유하며 소통하세요.
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PcVerSectionSecond;
