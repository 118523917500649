import React, { useEffect, useState } from 'react';
import '../../index.css';
import { css } from '@emotion/css';
import { BuddystockAppleStoreIcon, BuddystockGooglePlayIcon } from '../../static/images';
import IconButton from '../IconButton';
import { APP_STORE } from '../../constants';

const commonRootStyle = `
  position: fixed;
  bottom: 0;
  width: 100%;
  min-width: 320px;
  z-index: 40;
`;

const navStyle = (hiddenFooterNav: boolean) => css`
  ${commonRootStyle}
  display: ${hiddenFooterNav ? 'none' : 'block'};
  cursor: pointer;
`;

const containerStyle = (other: any) => css`
  display: flex;
  width: 92%;
  margin: 0 auto;
  justify-content: center;
  gap: 12px;
  ${other};
`;

const MobileVerFooterNav = () => {
  const [hiddenFooterNav, setHiddenFooterNav] = useState(false);
  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setHiddenFooterNav(true);
      } else {
        setHiddenFooterNav(false);
      }
    });
  }, []);

  return (
    <>
      <div className={navStyle(hiddenFooterNav)}>
        <div className={containerStyle({ padding: '17px 0px' })}>
          <IconButton
            isMobile
            size={'small'}
            label={'Google Play'}
            icon={<BuddystockGooglePlayIcon />}
            color={'primary'}
            width={162}
            onClick={() => window.open(APP_STORE.GOOGLE_PLAY, ' _blank')}
          />
          <IconButton
            isMobile
            size={'small'}
            label={'Apple Store'}
            icon={
              <BuddystockAppleStoreIcon
                className={css({
                  '& > path': {
                    fill: '#ffffff'
                  }
                })}
              />
            }
            color={'primary'}
            width={162}
            onClick={() => window.open(APP_STORE.APPLE, ' _blank')}
          />
        </div>
      </div>
    </>
  );
};

export default MobileVerFooterNav;
