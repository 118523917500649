import React, { Fragment, useEffect, useRef, useState } from 'react';
import '../../index.css';
import { css } from '@emotion/css';
import { BuddystockLogoIcon, BuddystockTitleIcon } from '../../static/images';
import { OpenMail } from '../OpenMail';
import PcVerNavDownloadPopup from './pcVerNavDownloadPopup';

const navStyle = css`
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 30;
  border-bottom: 1px solid #ebebeb;
`;

const containerStyle = css`
  display: flex;
  width: 92%;
  max-width: 1008px;
  margin: 0 auto;
  padding: 16px 0px;
`;

const logoStyle = css`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const buttonSectionStyle = css`
  grid-column: 2;
  display: flex;
  flex: 1;
  justify-content: end;
  align-items: center;
`;

const textButtonStyle = css`
  border: none;
  background: none;
  margin-right: 48px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
`;

const appDownloadSection = css`
  position: relative;
`;

const downloadStyle = css`
  cursor: pointer;
  padding: 10px 36px;
  background: #091e42;
  color: #fff;
  border-radius: 4px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  border: none;

  &:hover {
    background: #253858;
  }
`;

const PcVerNav = () => {
  const [open, setOpen] = useState(false);

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      const target = e.target as Node;
      if (open && divRef.current && !divRef.current.contains(target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
  }, [divRef, open]);

  return (
    <Fragment>
      <div className={navStyle}>
        <div className={containerStyle}>
          <div className={logoStyle}>
            <img src={BuddystockLogoIcon} width={30} height={30} alt={'buddystock_logo'} />
            <img src={BuddystockTitleIcon} width={114} height={30} alt={'buddystock_title'} />
          </div>
          <div className={buttonSectionStyle}>
            <button
              className={textButtonStyle}
              onClick={() =>
                window.open('https://www.notion.so/buddystock/fd59cf9e0c0b434690d5052d76ea1737', ' _blank')
              }
            >
              커뮤니티 가이드라인
            </button>
            <button className={textButtonStyle}>
              <OpenMail value={'유튜버 제휴 문의'} isHeader={true} />
            </button>
            <div ref={divRef} className={appDownloadSection}>
              <button className={downloadStyle} onClick={() => setOpen(!open)}>
                앱 다운로드
              </button>
              {open && <PcVerNavDownloadPopup onClose={() => setOpen(false)} />}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PcVerNav;
