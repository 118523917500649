import React from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import { BuddystockGooglePlayIcon, BuddystockAppleStoreIcon, PcFirstTopImg } from '../../../static/images';
import {
  containerStyle,
  contentStyle,
  descriptionTextStyle,
  imageBoxStyle,
  mainTextStyle,
  rootStyle,
  topImageStyle
} from '../../../style/style';
import IconButton from '../../IconButton';
import { APP_STORE } from '../../../constants';

const buttonDivStyle = css`
  display: flex;
  justify-content: start;
`;

const PcVerSectionFirst = () => (
  <div className={rootStyle()}>
    <div className={containerStyle}>
      <div className={contentStyle({ padding: '177px 0px' })}>
        <div className={css({ width: '420px' })}>
          <h1 className={mainTextStyle({ fontSize: '50px', width: '287px', lineHeight: '70px', margin: 0 })}>
            솔직한 투자 이야기는 버디스탁에서
          </h1>
          <div className={descriptionTextStyle({ marginBottom: '80px' })}>
            주식과 코인으로 재테크 하는 세상, <br />
            가까운 내 지인들은 무엇을 거래하고 있을까요?
          </div>
          <div className={buttonDivStyle}>
            <IconButton
              label={'Google Play'}
              icon={<BuddystockGooglePlayIcon />}
              color={'primary'}
              width={200}
              onClick={() => window.open(APP_STORE.GOOGLE_PLAY, ' _blank')}
            />
            <IconButton
              label={'Apple Store'}
              icon={
                <BuddystockAppleStoreIcon
                  className={css({
                    '& > path': {
                      fill: '#ffffff'
                    }
                  })}
                />
              }
              color={'primary'}
              width={200}
              onClick={() => window.open(APP_STORE.APPLE, ' _blank')}
            />
          </div>
        </div>
        <div className={imageBoxStyle({ width: '460px', height: '440px' })}>
          <div className={topImageStyle({ top: '-2%' })}>
            <img src={PcFirstTopImg} alt={'buddystock_top_img'} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PcVerSectionFirst;
