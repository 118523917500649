import { css } from '@emotion/css';

export const rootStyle = (bgColor?: string | undefined) => css`
  background-color: ${bgColor || 'fff'};
  width: 100%;
`;

export const containerStyle = css`
  display: flex;
  width: 92%;
  max-width: 952px;
  margin: 0 auto;
  justify-content: center;
`;

export const contentStyle = (other?: any) => css`
  ${other};
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const titleTextStyle = css`
  color: #00aeb0;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.2px;
  margin-bottom: 24px;
`;

export const mainTextStyle = (other?: any) => css`
  ${other};
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.2px;
  word-break: keep-all;
`;

export const descriptionTextStyle = (other?: any) => css`
  ${other};
  margin-top: 20px;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.2px;
  color: #7a869a;
  word-break: keep-all;
  line-height: 26px;
`;

export const imageBoxStyle = (other?: any) => css`
  position: relative;
  ${other};
`;

export const topImageStyle = (other?: any) => css`
  position: absolute;
  z-index: 2;
  ${other};
`;

export const middleImageStyle = (other?: any) => css`
  position: absolute;
  z-index: 1;
  ${other};
`;

export const bottomImageStyle = (other?: any) => css`
  position: absolute;
  ${other};
`;
