import React from 'react';
import '../../../index.css';
import { topImageStyle } from '../../../style/style';
import { MobileFifthTopImg } from '../../../static/images';
import MobileDefaultTemplate from '../MobileDefaultTemplate';

const MobileVerSectionFifth = () => (
  <MobileDefaultTemplate
    backgroundColor={'#F2F3F5'}
    title={'자유로운 커뮤니티'}
    text={'익명 버디들이 친구들과 공유하는 솔직한 투자 이야기'}
    textWidth={280}
    description={'커뮤니티에서 만난 새로운 버디들이 실제로 거래한 주식/코인에 대해 자유롭게 얘기해봐요.'}
    desWidth={204}
    imgContainerStyle={{ margin: '26px auto 43px' }}
  >
    <div className={topImageStyle()}>
      <img src={MobileFifthTopImg} alt={'buddystock_top_img'} />
    </div>
  </MobileDefaultTemplate>
);

export default MobileVerSectionFifth;
