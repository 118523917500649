import React from 'react';
import '../../../index.css';
import MobileDefaultTemplate from '../MobileDefaultTemplate';
import { topImageStyle } from '../../../style/style';
import { MobileSecondTomImg } from '../../../static/images';

const MobileVerSectionSecond = () => (
  <MobileDefaultTemplate
    title={'실시간 매매 내역'}
    text={'친구가 사고 파는 주식과 코인을 실시간으로'}
    textWidth={236}
    description={
      <span>
        연락처와 닉네임으로 친구를 찾고, <br /> 친구들의 주식과 코인 거래 정보를 실시간으로 공유하며 소통하세요.
      </span>
    }
    desWidth={280}
    imgContainerStyle={{ margin: '26px auto 80px' }}
  >
    <div className={topImageStyle({})}>
      <img src={MobileSecondTomImg} alt={'buddystock_top_img'} />
    </div>
  </MobileDefaultTemplate>
);

export default MobileVerSectionSecond;
