import React, { ReactNode } from 'react';
import { OpenMail } from '../OpenMail';
import { css } from '@emotion/css';
import { APP_STORE } from '../../constants';

const termsTextStyle = css`
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.2px;
  color: #42526e;
`;

interface IItem {
  label: ReactNode;
  event?: () => void;
}

export const FOOTHER_ITEMS: IItem[] = [
  {
    label: 'Appstore 다운로드',
    event: () => window.open(APP_STORE.APPLE, ' _blank')
  },
  {
    label: 'Google Play 다운로드',
    event: () => window.open(APP_STORE.GOOGLE_PLAY, ' _blank')
  },
  {
    label: <OpenMail value={'문의하기'} />
  },
  {
    label: (
      <a href={'/terms'} target="_blank" className={termsTextStyle} rel="noreferrer">
        이용약관
      </a>
    )
  },
  {
    label: (
      <a href={'/policy'} target="_blank" className={termsTextStyle} rel="noreferrer">
        개인정보 처리방침
      </a>
    )
  }
];
