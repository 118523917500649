import React, { Fragment } from 'react';
import { css } from '@emotion/css';
import { OpenMail } from '../OpenMail';
import { BuddystockAppleStoreIcon, BuddystockGooglePlayIcon } from '../../static/images';
import { APP_STORE } from '../../constants';

const menuPopupStyle = css`
  position: absolute;
  top: 62px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 100;
  padding-top: 12px;
`;

const menuItemStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 0;
  margin: 0 20px;
  gap: 4px;
  cursor: pointer;
  border-bottom: 1px solid #f2f3f5;
  span {
    font-family: Spoqa Han Sans Neo;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const dividerStyle = css`
  width: 100%;
  height: 1px;
  color: #f2f3f5;
  display: flex;
  justify-content: center;
`;

const MobileVerHeaderNavMenu = () => {
  const menuItems = [
    {
      id: 1,
      label: '커뮤니티 가이드라인',
      onClick: () => window.open('https://www.notion.so/buddystock/fd59cf9e0c0b434690d5052d76ea1737')
    },
    {
      id: 2,
      label: <OpenMail value={'유튜버 제휴 문의하기'} isHeader={true} />
    },
    {
      id: 3,
      label: 'Google Play 다운로드',
      icon: <BuddystockGooglePlayIcon />,
      onClick: () => window.open(APP_STORE.GOOGLE_PLAY, '_black')
    },
    {
      id: 4,
      label: 'App Store 다운로드',
      icon: <BuddystockAppleStoreIcon />,
      onClick: () => window.open(APP_STORE.APPLE, '_black')
    }
  ];

  return (
    <div className={menuPopupStyle}>
      {menuItems.map((item, index) => (
        <Fragment key={item.id}>
          <div className={menuItemStyle} key={index} onClick={() => item.onClick && item.onClick()}>
            {item.icon && <div>{item.icon}</div>}
            <span>{item.label}</span>
          </div>
          <div className={dividerStyle} />
        </Fragment>
      ))}
    </div>
  );
};

export default MobileVerHeaderNavMenu;
