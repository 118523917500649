import React, { ReactNode } from 'react';
import { css } from '@emotion/css';

const aTagStyle = (isHeader: boolean) => css`
  text-decoration: none;
  color: ${isHeader ? '#3D3D3D' : '#42526E'};
`;

export const OpenMail = ({ value, isHeader = false }: { value: string | ReactNode; isHeader?: boolean }) => (
  <a href="mailto:admin@pickstudio.io" className={aTagStyle(isHeader)}>
    {value}
  </a>
);
