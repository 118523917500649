import React from 'react';
import '../../../index.css';
import { css } from '@emotion/css';
import MobileDefaultTemplate from '../MobileDefaultTemplate';
import { OpenMail } from '../../OpenMail';

const customRootStyle = css`
  position: relative;
  width: 100%;
  min-width: 320px;
  display: grid;
  justify-content: center;
  padding-top: 102px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #253858;
    opacity: 0.6;
  }

  background-image: url(./buddystock_sixth.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  & > div {
    z-index: 1;
  }
`;

const subDescriptionStyle = css`
  color: #fff;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 160px 0 20px;
`;

const buttonDivStyle = css`
  display: flex;
  justify-content: center;
`;

const buttonStyle = css`
  width: 272px;
  height: 56px;
  background-color: #fff;
  border-radius: 4px;
  border: none;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;

  &:hover {
    background-color: #f2f3f5;
  }
`;

const MobileVerSectionSixth = () => (
  <MobileDefaultTemplate
    isTextColorChange
    customRootStyle={customRootStyle}
    title={'COMING SOON'}
    text={'버디스탁에서만 만날 수 있는 유튜버의 투자 노하우'}
    textWidth={280}
    description={'유튜버의 주식/코인 매매 내역을 곧 실시간으로 볼 수 있어요.'}
    desWidth={183}
    imgContainerStyle={{ margin: '26px auto 0px' }}
  >
    <div>
      <div className={subDescriptionStyle}>유튜버와 함께하는 편리한 성장 파트너, 버디스탁</div>
      <div className={buttonDivStyle}>
        <OpenMail value={<button className={buttonStyle}>유튜버 제휴 문의하기 </button>} isHeader={true} />
      </div>
    </div>
  </MobileDefaultTemplate>
);

export default MobileVerSectionSixth;
