import React from 'react';
import { css } from '@emotion/css';
import { BuddystockAppleStoreIcon, BuddystockGooglePlayIcon } from '../../static/images';
import { APP_STORE } from '../../constants';
import { CloseIcon } from '../../static/icons';

const rootStyle = css`
  position: absolute;
  width: 232px;
  padding: 24px 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 1px 5px 5px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  top: 44px;
  right: 0;
`;

const downloadButtonContainerStyle = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const downloadButtonStyle = css`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  background: none;
  border: none;
`;

const closeButtonStyle = css`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  background: none;
  border: none;
`;

const PcVerNavDownloadPopup = ({ onClose }: { onClose: () => void }) => {
  const downloadItems = [
    {
      label: 'Google Play 다운로드',
      icon: <BuddystockGooglePlayIcon />,
      onClick: () => window.open(APP_STORE.GOOGLE_PLAY, '_black')
    },
    {
      label: 'App Store 다운로드',
      icon: <BuddystockAppleStoreIcon />,
      onClick: () => window.open(APP_STORE.APPLE, '_black')
    }
  ];

  return (
    <div className={rootStyle}>
      <div className={downloadButtonContainerStyle}>
        {downloadItems.map(item => (
          <button className={downloadButtonStyle} key={item.label} onClick={item.onClick}>
            <div>{item.icon}</div>
            <div>{item.label}</div>
          </button>
        ))}
      </div>
      <button className={closeButtonStyle} onClick={onClose}>
        <img src={CloseIcon} />
      </button>
    </div>
  );
};

export default PcVerNavDownloadPopup;
